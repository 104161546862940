/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */

import Carousel from './components/Carousel';
import Divider from './components/Divider';
import Hero from './components/Hero';
import HowToPlay from './components/HowToPlay';
import Nft from './components/Nft';

import x from './assets/socials/x.png';
import telegram from './assets/socials/telegram.png';
import dex from './assets/socials/dex.png';
import magic from './assets/socials/magiceden.png';
import discord from './assets/socials/discord.png';
import limbo from './assets/limbo.png';

function App() {
  return (
    <>
      <div className="app">
        <Hero />
        {/* <Divider classname="first">
          <div className="flex column">
            <div className="japan">遊び方</div>
            <div className="text">HOW TO PLAY</div>
          </div>
        </Divider>
        <HowToPlay />
        <Divider classname="second">
          <div className="flex column">
            <div className="japan">くまモンをゲット</div>
            <div className="text">WHY DO YOU NEED A KUMAMONEY NFT</div>
          </div>
        </Divider>
        <Carousel />
        <Nft />
        <Divider classname="third">
          <div className="flex column">
            <div className="japan gray">ライフ. ミステリー. 楽しい.</div>
            <div className="text black">
              MORE LIVES. MORE MYSTERY. MORE FUN.
            </div>
            <div className="button-play">MINT NOW</div>
          </div>
        </Divider>
        <Divider classname="fourth">
          <div className="flex row space">
            <div className="flex row footer-socials">
              <img src={x} alt="" />
              <img src={telegram} alt="" />
              <img src={dex} alt="" />
              <img src={magic} alt="" />
              <img src={discord} alt="" />
            </div>
            <div
              className="text black small ccc"
              style={{ fontWeight: '500', color: '#fff' }}
            >
              <span style={{ fontWeight: '900' }}>KUMAMONEY</span> IS A{' '}
              <span style={{ fontWeight: '900' }}>LIMBO LABS</span> PROJECT 2024
            </div>
            <img src={limbo} alt="limbo" />
          </div>
        </Divider> */}
      </div>
    </>
  );
}

export default App;
