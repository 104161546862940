import title from '../assets/title.png';
import kumamon from '../assets/kumamon.png';
import l_pupil from '../assets/l_pupil1.png';
import r_pupil from '../assets/r_pupil1.png';

// title
import top from '../assets/TITLE/Nowy folder/_0000_top.png';
import bottom from '../assets/TITLE/Nowy folder/_0001_bottom.png';
import k from '../assets/TITLE/Nowy folder/_0002_K.png';
import u from '../assets/TITLE/Nowy folder/_0003_U.png';
import m from '../assets/TITLE/Nowy folder/_0004_M.png';
import a from '../assets/TITLE/Nowy folder/_0005_A.png';
import m2 from '../assets/TITLE/Nowy folder/_0006_M.png';
import o from '../assets/TITLE/Nowy folder/_0007_O.png';
import n from '../assets/TITLE/Nowy folder/_0008_N.png';
import e from '../assets/TITLE/Nowy folder/_0009_E.png';
import y from '../assets/TITLE/Nowy folder/_0010_Y.png';

import { useState } from 'react';
import { motion } from 'framer-motion';
import HeroButtons from './Hero_buttons';
import HeroButtonsTemp from './Hero_buttons copy';

const Hero = () => {
  const [leftPupilPosition, setLeftPupilPosition] = useState({ x: 0, y: 0 });
  const [rightPupilPosition, setRightPupilPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    const imageStack = document.querySelector('.image-stack');
    const stackRect = imageStack.getBoundingClientRect();

    const mouseX = event.clientX - stackRect.left;
    const mouseY = event.clientY - stackRect.top;

    // Adjust for each eye individually
    setPupilPosition(mouseX, mouseY, setLeftPupilPosition, 'left_pupil');
    setPupilPosition(mouseX, mouseY, setRightPupilPosition, 'right_pupil');
  };

  const setPupilPosition = (mouseX, mouseY, setPosition, pupilId) => {
    const pupil = document.getElementById(pupilId);
    const stackRect = document
      .querySelector('.image-stack')
      .getBoundingClientRect();
    const deltaX = mouseX - stackRect.width / 2;
    const deltaY = mouseY - stackRect.height / 2;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    let posX, posY;

    if (distance <= 20) {
      posX = deltaX;
      posY = deltaY;
    } else {
      const angle = Math.atan2(deltaY, deltaX);
      posX = Math.cos(angle) * 20;
      posY = Math.sin(angle) * 20;
    }

    setPosition({ x: posX, y: posY });
  };

  const images = [k, u, m, a, m2, o, n, e, y]; // Assuming k, u, m, a, m2, o, n are the image sources

  const staggerVariants = {
    visible: {
      transition: {
        staggerChildren: 0.2, // Adjust the stagger delay as needed
      },
    },
  };

  const imageVariants = {
    hidden: {
      opacity: 0,
      y: -50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <div className="hero" onMouseMove={handleMouseMove}>
      <div className="empty"></div>
      {/* <img className="title" src={title}></img> */}

      {/* <div className="kumamon-container">
          <img className="kumamon" src={kumamon}></img>
          <img className="l" src={l_pupil}></img>
          <img className="r" src={r_pupil}></img>
        </div> */}
      <div className="title-container">
        <motion.img
          className="letter"
          src={top}
          initial={{ x: -1600 }}
          animate={{ x: 0 }}
          transition={{
            duration: 100,
            type: 'spring',
            damping: 20,
            stiffness: 100,
            ease: 'easeIn',
            delay: 1.5,
          }}
        ></motion.img>
        {/* <div className="center">
            <img className="letter" src={k}></img>
            <img className="letter" src={u}></img>
            <img className="letter" src={m}></img>
            <img className="letter" src={a}></img>
            <img className="letter" src={m2}></img>
            <img className="letter" src={o}></img>
            <img className="letter" src={n}></img>
          </div> */}

        <motion.div
          className="center"
          variants={staggerVariants}
          initial="hidden"
          animate="visible"
        >
          {images.map((image, index) => (
            <motion.img
              key={index}
              src={image}
              className="letter"
              variants={imageVariants}
            />
          ))}
        </motion.div>

        <motion.img
          className="bottom"
          src={bottom}
          initial={{ x: 1600 }}
          animate={{ x: 0 }}
          transition={{
            duration: 100,
            type: 'spring',
            damping: 20,
            stiffness: 100,
            ease: 'easeIn',
            delay: 1.6,
          }}
        ></motion.img>
      </div>
      <HeroButtonsTemp />
      <motion.div
        className="image-stack"
        initial={{ y: 300 }}
        animate={{ y: 1 }}
        transition={{
          duration: 100,
          type: 'spring',
          damping: 20,
          stiffness: 100,
          ease: 'easeIn',
        }}
      >
        <img className="layer" src={kumamon}></img>
        <img
          id="left_pupil"
          className="layer"
          src={l_pupil}
          alt="left_pupil"
          style={{
            transform: ` translate(${leftPupilPosition.x}px, ${leftPupilPosition.y}px)`,
          }}
        ></img>
        <img
          id="right_pupil"
          className="layer"
          src={r_pupil}
          alt="right_pupil"
          style={{
            transform: ` translate(${rightPupilPosition.x}px, ${rightPupilPosition.y}px)`,
          }}
        ></img>
      </motion.div>
    </div>
  );
};

export default Hero;
