import { useState } from 'react';
import './Hero_buttons.css';
import { motion } from 'framer-motion';

import x from '../assets/socials/x.png';
import telegram from '../assets/socials/telegram.png';
import dex from '../assets/socials/dex.png';
import magic from '../assets/socials/magiceden.png';
import discord from '../assets/socials/discord.png';

const HeroButtonsTemp = () => {
  const [minted, setminted] = useState(2222);
  return (
    <>
      <motion.div
        className="button_container temp"
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          delay: 2,
          duration: 400,
          type: 'spring',
          damping: 80,
          stiffness: 100,
          ease: 'easeIn',
        }}
      >
        <div className="comingsoon">COMING SOON</div>
        <div className="socials">
          <div className="sbtn rounded-left">
            <a
              href="https://x.com/kumamoney_sol"
              target="_blank"
              rel="noreferrer"
            >
              <img src={x} alt="x" />
            </a>
          </div>
          <div className="sbtn">
            <a href="https://t.me/kumamoney" target="_blank" rel="noreferrer">
              <img src={telegram} alt="telegram" />
            </a>
          </div>
          <div className="sbtn nohover" style={{ opacity: '0.5' }}>
            <img src={dex} alt="dexscreener" />
          </div>
          <div className="sbtn" style={{ opacity: '0.5' }}>
            <img src={magic} alt="magic eden" />
          </div>
          <div className="sbtn rounded-right" style={{ opacity: '0.5' }}>
            <img src={discord} alt="discord" />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default HeroButtonsTemp;
