import { useState, useEffect } from 'react';

import { motion, useViewportScroll } from 'framer-motion';

import './Carousel.css';
import i1 from '../assets/images/1.png';
import i2 from '../assets/images/2.png';
import i3 from '../assets/images/3.png';
import i4 from '../assets/images/4.png';
import i5 from '../assets/images/5.png';
import i6 from '../assets/images/6.png';
import i7 from '../assets/images/7.png';
import i8 from '../assets/images/8.png';
import i9 from '../assets/images/9.png';
import i10 from '../assets/images/10.png';
import i11 from '../assets/images/11.png';
import i12 from '../assets/images/12.png';
import i13 from '../assets/images/13.png';
import i14 from '../assets/images/14.png';
import i15 from '../assets/images/15.png';
import i16 from '../assets/images/16.png';
import i17 from '../assets/images/17.png';
import i18 from '../assets/images/18.png';

import i19 from '../assets/images/19.png';
import i20 from '../assets/images/20.png';
import i21 from '../assets/images/21.png';
import i22 from '../assets/images/22.png';
import i23 from '../assets/images/23.png';
import i24 from '../assets/images/24.png';
import i25 from '../assets/images/25.png';
import i26 from '../assets/images/26.png';
import i27 from '../assets/images/27.png';
import i28 from '../assets/images/28.png';
import i29 from '../assets/images/29.png';
import i30 from '../assets/images/30.png';
import i31 from '../assets/images/31.png';
import i32 from '../assets/images/32.png';
import i33 from '../assets/images/33.png';
import i34 from '../assets/images/34.png';
import i35 from '../assets/images/35.png';
import i36 from '../assets/images/36.png';

const Carousel = () => {
  const [yPos, setYpos] = useState(0);

  const images = [
    { id: 1, url: i1 },
    { id: 2, url: i2 },
    { id: 3, url: i3 },
    { id: 4, url: i4 },
    { id: 5, url: i5 },
    { id: 6, url: i6 },
    { id: 7, url: i7 },
    { id: 8, url: i8 },
    { id: 9, url: i9 },
    { id: 10, url: i10 },
    { id: 11, url: i11 },
    { id: 12, url: i12 },
    { id: 13, url: i13 },
    { id: 14, url: i14 },
    { id: 15, url: i15 },
    { id: 16, url: i16 },
    { id: 17, url: i17 },
    { id: 18, url: i18 },
  ];
  const images2 = [
    { id: 1, url: i19 },
    { id: 2, url: i20 },
    { id: 3, url: i21 },
    { id: 4, url: i22 },
    { id: 5, url: i23 },
    { id: 6, url: i24 },
    { id: 7, url: i25 },
    { id: 8, url: i26 },
    { id: 9, url: i27 },
    { id: 10, url: i28 },
    { id: 11, url: i29 },
    { id: 12, url: i30 },
    { id: 13, url: i31 },
    { id: 14, url: i32 },
    { id: 15, url: i33 },
    { id: 16, url: i34 },
    { id: 17, url: i35 },
    { id: 18, url: i36 },
  ];
  useEffect(() => {
    function handleScroll() {
      const yPos = window.scrollY;
      setYpos(yPos - 1600);
    }
    window.addEventListener('scroll', handleScroll, false);
    return () => {
      window.removeEventListener('scroll', handleScroll, false);
    };
  }, [yPos]);

  return (
    <div className="carousel-container">
      <motion.div
        className="tt"
        initial={{ x: -400, opacity: 0 }}
        animate={{ x: 0, opacity: 1.0 }}
        transition={{ duration: 0.3, delay: 1.2 }}
        style={{ translateX: yPos }}
      >
        {images.map((props) => (
          <div key={props.id}>
            <motion.img
              className="carousel-img"
              src={props.url}
              alt=""
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
            />
          </div>
        ))}
      </motion.div>

      <motion.div
        className="tt"
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1.0 }}
        transition={{ duration: 0.3, delay: 1.4 }}
        style={{ translateX: -yPos }}
      >
        {images2.map((props) => (
          <div key={props.id}>
            <motion.img
              className="carousel-img"
              src={props.url}
              alt=""
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
            />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Carousel;
